import "./aboutus.css";
import React from "react";
import { Typography, Box, Grid, Link } from "@mui/material";

import { LinkedIn, Email } from "@mui/icons-material";

export default function Aboutus() {
  return (
    <Box
      // justifyContent={"center"}
      // alignItems={"center"}
      borderRadius={"1rem"}
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <Typography
        //variant="h4"
        ml={{  xs:"3rem",sm: "2rem",md:"8rem" , lg:"9rem"}}
        align="left"
        mt={{ xs:"3.5rem" ,sm :"4rem",md:"8rem"}}
        sx={{
          letterSpacing: ".2rem",
          color: "#40E0D0",
          fontWeight: { xs: 100, sm: 200, md: 600, lg: 700, xl: 800 },
          fontSize:{ xs:"1.2rem",sm:"1.5rem",md:"2rem",}
        }}
      >
        Who We Are ?
      </Typography>
      <Typography
        mt={{xs:".3rem", sm:"1.2rem",md:"1.4rem"}}
        mr={4}
        ml={4}
        variant="h5"
        align="center"
        sx={{
          typography: {
            xl: "h4",
            lg: "h5",
            md: "h5",
            sm: "subtitle1",
            xs: "subtitle1",
          },
          display: { xs: "flex", md: "flex" },
          fontWeight: 500,
          letterSpacing: ".2rem",
          color: "#40E0D0",
          justifyContent: "auto",
          textDecoration: "none",
        }}
      >
        Steed Pharmaceutical Trading LLC is a newly established company in UAE.
        We are engaged in the sales and distribution of pharmaceutical products
        and medicines to provide Top Quality products, services, and support to
        our customers in the medical industries. We have a rooted relationship
        with various local healthcare providers in UAE and we work together with
        our clients to review their achievements and address any gaps between
        their business goals, actual performance and, potential. Steed
        Pharmaceutical is Looking to build a new concept of health care provider
        with last and updated medication to bring to our customers and patients
        the best treatments services.
      </Typography>

      <Grid container marginTop={8}>
        <Grid item lg={1} md={1}>
          <LinkedIn
            sx={{ fontSize: 45, color: "#40E0D0", marginLeft: "4rem" }}
          />
        </Grid>

        <Grid item lg={5} md={5}>
          <Link
            href="https://www.linkedin.com/company/steed-pharmaceuticals-trading/"
            underline="none"
          >
            <Typography
              noWrap
              sx={{
                typography: {
                  lg: "h6",
                  md: "h6",
                  sm: "subtitle1",
                  xs: "subtitle1",
                },
                marginTop: ".7rem",
                fontWeight: 500,
                letterSpacing: ".2rem",
                color: "#40E0D0",
                display: { xs: "flex", md: "flex" },
                justifyContent: "auto",
                textDecoration: "none",
              }}
            >
              Steed Pharmaceutical Trading
            </Typography>
          </Link>
        </Grid>

        <Grid item lg={1} md={1}>
          <Email
            sx={{
              fontSize: 45,
              color: "#40E0D0",
              marginLeft: "4rem",
            }}
          />
        </Grid>

        <Grid item lg={5} md={5}>
          <Typography
            sx={{
              typography: {
                lg: "h6",
                md: "h6",
                sm: "subtitle1",
                xs: "subtitle1",
              },
              marginTop: ".7rem",
              fontWeight: 500,
              letterSpacing: ".2rem",
              color: "#40E0D0",
              display: { xs: "flex", md: "flex" },
              justifyContent: "auto",
              textDecoration: "none",
            }}
          >
            kais.chahem@steedpharma.com
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

import React from "react";
import { Container, Box } from "@mui/material";
import Aboutus from "../components/AboutUs/aboutus";
import Comingsoon from "../components/ComingSoon/comingsoon";
import Header from "../components/Header/header";

const Home = () => {
  return (
    <div>
      <Header />
      <Container>
        <Box
          display={"flex"}
          alignItems={"inherit"}
          justifyContent={"center"}
          sx={{ width: "100%", height: "100vh" }}
        >
          <Comingsoon />
        </Box>
      </Container>
      <Box
        id={"aboutus"}
        display={"flex"}
        alignItems={"inherit"}
        justifyContent={"center"}
        sx={{ width: "100%", height: "100vh" }}
      >
        <Aboutus />
      </Box>
    </div>
  );
};

export default Home;

import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from "@mui/material";

const Header = () => {
  // const pages = ["About"];
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Box
            position={"relative"}
            component="img"
            sx={{
              height: 100,
              width: 100,
              maxHeight: { xs: 50, md: 100 },
              maxWidth: { xs: 50, md: 100 },
            }}
            alt="logo"
            src="../../../assets/logo.png"
          />
          <Typography
            // variant="h5"
            noWrap
            // component={Link}
            // to="/"
            sx={{
              fontSize:{xs:"1.2rem",sm:"1.2rem" ,md:"1.8rem", lg:"1.8rem", xl:"2rem"},
              display: { xs: "felx", sm: "flex" },
              fontFamily: "monospace",
              fontWeight: { lg: 700, md: 700, sm: 500, xs: 100 },
              letterSpacing: { lg: ".3rem", md: ".1rem", sm: "none" },
              color: "black",
              textDecoration: "none",
              flexGrow: 1,
            }}
          >
            Steed Pharmaceutical Trading
          </Typography>

          <Box
            paddingRight={20}
            sx={{
              // flexGrow: 1,
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            <Link
              href="#aboutus"
              underline="none"
              color="black"
              variant="button"
              sx={{
                p: ".8rem",
                letterSpacing: ".3rem",
                lineHeight: 1.5,
                fontSize: "1rem",
                fontWeight: 600,
                border: "3px solid",
                borderColor: "#48D1CC",
                fontFamily: "monospace",
                borderRadius: ".5rem",

                "&:hover": {
                  border: "3px solid",
                  borderColor: "#48D1CC",
                },
              }}
            >
              About
            </Link>
            {/* {pages.map((page) => (
              <Button
                variant="outlined"
                size="medium"
                key={page}
                sx={{
                  color: "black",
                  letterSpacing: ".3rem",
                  lineHeight: 1.5,
                  fontSize: 16,
                  fontWeight: 500,
                  border: "3px solid",
                  borderColor: "#48D1CC",
                  fontFamily: "monospace",
                  "&:hover": {
                    border: "3px solid",
                    borderColor: "#48D1CC",
                  },
                }}
                
              >
                {page}
              </Button>
            ))} */}
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;

import React from "react";
import Home from "./pages/Home";
import { Routes, Route, Link } from "react-router-dom";
import Aboutus from "./components/AboutUs/aboutus";
import "./App.css"


function App() {
  return (
    <div className="App backgroundImage">
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<Aboutus />} />
      </Routes>
      </div>
  );
}

export default App;

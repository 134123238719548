//import './comingsoon.css'
import { Box, Typography } from "@mui/material";
import React from "react";

export default function Comingsoon() {
  return (
    <Box>
      <Typography
        variant="h4"
        align="center"
        justifyContent={"center"}
        alignItems={"center"}
        mt={{ xs:"14rem",sm:"14rem",md:"18rem" ,lg:"20rem", xl:"22rem"}}
        sx={{
          display: { xs: "flex", md: "flex" },
          fontSize:{xs:"1.2rem",sm:"1.2rem" ,md:"2.3rem", lg:"2.3rem", xl:"2.3rem"},
          fontWeight:{ xs: 200, sm: 200, md: 400, lg: 500, xl: 700 },
          letterSpacing: { sx:"none", sm:".5rem",md:"1rem",lg:"1rem",xl:"1rem" },
          color: "inherit",
          textDecoration: "none",
        }}
      >
        STAY TUNED , WE ARE
      </Typography>
      <Typography
        //variant="h1"
        alignContent={"center"}
        align="center"
        mt={{ xs:"1rem",sm:"1.1rem",md:"1.2rem" ,lg:"1.4rem", xl:"1.4rem"}}
        sx={{
          display: { xs: "flex", md: "flex" },
          justifyContent: "auto",
          fontSize:{xs:"2.2rem",sm:"2.2rem" ,md:"6.5rem", lg:"6.5rem", xl:"6.5rem"},
          fontWeight: 600,
          letterSpacing: "2rem",
          color: "inherit",
          textDecoration: "none",
        }}
      >
        COMING SOON
      </Typography>
     
    </Box>
  );
}
